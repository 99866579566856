import { flow, getEnv, getRoot, types, getSnapshot } from "mobx-state-tree"
import { sortBy } from "lodash"
import Log from "models/log/log"
import SoundTuningService from "../../services/sound_tuning_service"
import { mapKeysToCamelCase } from "../../utils/helpers"
import endOfDay from "date-fns/endOfDay"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const GET_EVENTS_API_PATH = `/v1/php/events`
const PATCH_EVENTS_API_PATH = `/v1/php/event`
const ALL = "all"
const USER = "user"
const SYSTEM = "system"

const LogsStore = types
  .model("LogsStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    logs: types.optional(types.map(Log), {}),
    currentLogTitle: types.maybeNull(types.string),
    currentLogDate: types.maybeNull(types.string),
    currentEventLogId: types.maybeNull(types.string),
    startDate: types.maybeNull(types.Date),
    endDate: types.maybeNull(types.Date),
    minDate: types.maybeNull(types.Date),
    page: types.optional(types.number, 1),
    lastPage: types.optional(types.number, 1),
    type: types.optional(types.enumeration([ALL, USER, SYSTEM]), ALL),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isLoading() {
        return self.state === LOADING
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      get logsArray() {
        return Array.from(self.logs.values())
      },
      get lastLogWithStatus() {
        const filterLogs = self.logsArray.filter(
          (log) =>
            log.status &&
            (log.status === "RECEIVED" ||
              log.status === "SENDING" ||
              log.status === "APPLIED")
        )
        if (filterLogs[0]?.status !== "APPLIED") {
          return filterLogs[0]
        } else {
          return null
        }
      },
      get logsList() {
        if (self.endLoading) {
          return self.logsArray
        } else {
          return null
        }
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setCurrentLogTitle(value) {
        self.currentLogTitle = value
      },
      setCurrentLogDate(value) {
        self.currentLogDate = value
      },
      setCurrentEventLogId(value) {
        self.currentEventLogId = value
      },
      initSocketLogs() {
        const { socket } = getRoot(self).socketStore

        const fetchConfigAndHearingAssessment = () => {
          getRoot(self).configurationStore.reset()
          getRoot(self).configurationStore.fetchConfiguration()
          getRoot(self).hearingEvaluationStore.reset()
          getRoot(self).hearingEvaluationStore.fetchHearingEvaluation()
        }

        socket.onAny((eventName, response) => {
          const today = new Date()
          const initDate = self.startDate || self.minDate
          let lastDate

          if (self.endDate) {
            lastDate = self.endDate
          } else if (self.startDate) {
            lastDate = self.startDate
          } else {
            lastDate = today
          }

          const addNewEvent =
            self.page === 1 && today >= initDate && today <= endOfDay(lastDate)

          if (addNewEvent) {
            switch (eventName) {
              case "newDbConfiguration":
              case "newHearingAssessment":
                self.addLog(mapKeysToCamelCase(response), true)
                fetchConfigAndHearingAssessment()
                break
              case "updateHearingAssessment":
                fetchConfigAndHearingAssessment()
                break
              case "newPhpConfiguration":
              case "newTutorialNotification":
              case "firmwareUpdate":
              case "initialPairing":
              case "diagnosticReport":
                self.addLog(mapKeysToCamelCase(response), true)
                break
              case "addPhpEvent":
                self.addLog(mapKeysToCamelCase(response), true)
                break
              case "updatePhpEvent":
                self.addLog(mapKeysToCamelCase(response))
                break
              case "tutorialRead":
                getRoot(self).journalStore.reset()
                getRoot(self).journalStore.fetchJournal()
                break
              default:
                return null
            }
          }
        })
      },
      stopSocketLogs() {
        const { socket } = getRoot(self).socketStore
        socket.offAny()
      },
      fetchLogs: flow(function* fetch() {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        const params = new URLSearchParams({ page: self.page, type: self.type })

        if (self.startDate) {
          params.set("start_date", self.startDate)
        }
        if (self.endDate) {
          params.set("end_date", self.endDate)
        }

        const url = `${GET_EVENTS_API_PATH}?${params.toString()}`

        self.startLoading()
        yield apiClient.requestManager(
          () => apiClient.get(url),
          (response) => {
            response.data.phpEvents.forEach((event) => {
              self.addLog(event)
            })
            self.setLastPage(response.data.lastPage)
            self.setMinDate(response.data.minDate)
            self.endLoading()
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
      }),
      addLog({ id, type, payload, createdAt, message, status, notes }, top = false) {
        if (id) {
          if (payload !== {}) {
            payload.hearingProfiles = SoundTuningService.convertJsonToDisplayValuesInProfiles(
              sortBy(payload.hearingProfiles, "position")
            )
            if (payload.favourites) {
              payload.favourites = SoundTuningService.convertJsonToDisplayValuesInProfiles(
                payload.favourites
              )
            }
            if (payload.hearingAssessment) {
              payload.hearingAssessment.id = `${payload.hearingAssessment.id}`
            }
          }

          const event = {
            id: String(id),
            type,
            payload,
            createdAt,
            message,
            status,
            notes,
          }

          if (top) {
            const currentLogs = getSnapshot(self.logs)
            self.logs.clear()
            self.logs.set(String(id), event)
            Object.keys(currentLogs)
              .reverse()
              .forEach((key) => {
                if (key !== id.toString()) {
                  self.logs.set(key, currentLogs[key])
                }
              })
          } else {
            self.logs.set(String(id), event)
          }
        }
      },
      updateLog: flow(function* updateLog(id, notes) {
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        let body = null
        body = { notes }
        if (!body) return

        yield apiClient.requestManager(
          () =>
            apiClient.patch(
              `${PATCH_EVENTS_API_PATH}/${id}`,
              body
            ),
          () => {
            getRoot(self).uiStore.openNotification(
              `Log event successfully saved.`,
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `${e.errorResponse.data.message}`,
              "error"
            )
            self.setNotFoundError()
          }
        )
      }),
      setStartDate(value) {
        self.page = 1
        self.startDate = value
      },
      setEndDate(value) {
        self.page = 1
        self.endDate = value
      },
      clearLogsValues() {
        self.setCurrentLogTitle(null)
        self.setCurrentLogDate(null)
      },
      reset() {
        self.state = INITIAL
        self.logs.clear()
      },
      setPage(page, reload = true) {
        if (page > 0) {
          self.page = page
        }

        if (reload) {
          self.reload()
        }
      },
      setLastPage(lp) {
        self.lastPage = lp
      },
      setMinDate(date) {
        self.minDate = Date.parse(date)
      },
      reload() {
        self.reset()
        self.fetchLogs()
      },
      setType(type) {
        switch (type) {
          case 0:
            self.type = ALL
            break
          case 1:
            self.type = USER
            break
          case 2:
            self.type = SYSTEM
            break
          default:
            self.type = ALL
            break
        }
        self.reload()
      },
    }
  })

export default LogsStore
